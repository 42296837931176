// 订单详情页
<template>
  <div class="sld_sample_info">
    <!-- <MemberTitle :memberTitle="L['订单详情']"></MemberTitle> -->
    <div class="container">
      <h3>{{ L["订单详情"] }}</h3>

      <div class="outer7 flex-col" v-if="detailInfo.data.orderSn">
        <div class="outer8 flex-col">
          <div class="section1 flex-col">
            <div class="box1 flex-col">
              <div class="bd1 flex-col">
                <div class="section2 flex-row">
                  <span class="word25">订单号：{{detailInfo.data.orderSn}}</span>
                </div>
              </div>
              <div class="bd2 flex-row">
                <template
                  v-if="detailInfo.data.orderState == 0 || detailInfo.data.orderState == 21 || detailInfo.data.orderState == 60">
                  <div class="img1_box active">
                    <img class="img1" referrerpolicy="no-referrer" :src="pic_confirm" />
                    <p>提交订单</p>
                    <p>{{detailInfo.data.orderLogList[0].logTime}}</p>
                  </div>
                  <div class="bd3 flex-col"></div>
                  <div class="img1_box active">
                    <img class="img1" referrerpolicy="no-referrer" :src="pic_finish" />
                    <p v-if="detailInfo.data.orderState == 0">交易取消</p>
                    <p v-else-if="detailInfo.data.orderState == 21">订单结束</p>
                    <p v-else>交易关闭</p>
                    <p v-if="detailInfo.data.orderLogList.length>1">
                      {{detailInfo.data.orderLogList[detailInfo.data.orderLogList.length-1].logTime}}
                    </p>
                  </div>
                </template>
                <template v-else>
                  <div class="img1_box active">
                    <img class="img1" referrerpolicy="no-referrer" :src="pic_confirm" />
                    <p>提交订单</p>
                    <p>{{detailInfo.data.orderLogList[0].logTime}}</p>
                  </div>
                  <div class="bd3 flex-col" :class="{active:detailInfo.data.orderState >= 10}"></div>
                  <div class="img1_box" :class="{active:detailInfo.data.orderState >= 20}">
                    <img v-if="detailInfo.data.orderState < 20" class="img1" referrerpolicy="no-referrer"
                      :src="pic_uncheck" />
                    <img v-else class="img1" referrerpolicy="no-referrer" :src="pic_check" />
                    <p>供应商审核</p>
                    <p v-if="detailInfo.data.orderLogList.length>1 && detailInfo.data.orderState>=20">
                      {{detailInfo.data.orderLogList[1].logTime}}</p>
                  </div>
                  <div class="bd4 flex-col" :class="{active:detailInfo.data.orderState >= 20}"></div>
                  <div class="img1_box" :class="{active:detailInfo.data.orderState >= 30}">
                    <img v-if="detailInfo.data.orderState < 30" class="img1" referrerpolicy="no-referrer"
                      :src="pic_unsend" />
                    <img v-else class="img1" referrerpolicy="no-referrer" :src="pic_send" />
                    <p>供应商发货</p>
                    <p v-if="detailInfo.data.orderLogList.length>2 && detailInfo.data.orderState>=30">
                      {{detailInfo.data.orderLogList[2].logTime}}</p>
                  </div>
                  <div class="bd4 flex-col" :class="{active:detailInfo.data.orderState >= 30}"></div>
                  <div class="img1_box" :class="{active:detailInfo.data.orderState >= 40}">
                    <img v-if="detailInfo.data.orderState < 40" class="img1" referrerpolicy="no-referrer"
                      :src="pic_unfinish" />
                    <img v-else class="img1" referrerpolicy="no-referrer" :src="pic_finish" />
                    <p>订单完成</p>
                    <p v-if="detailInfo.data.orderLogList.length>3 && detailInfo.data.orderState>=40">
                      {{detailInfo.data.orderLogList[3].logTime}}</p>
                  </div>
                </template>
              </div>
              <div class="bd8 flex-col"></div>
              <div class="bd9 flex-row">
                <span class="word30">供应商名称：{{detailInfo.data.storeName}}</span>
                <img class="label5" referrerpolicy="no-referrer" :src="storeChat"
                  @click="chatInfo(detailInfo.data.storeId)" />
              </div>
              <div class="bd10 flex-row">
                <div class="group26 flex-col">
                  <span class="word32">收货人：{{detailInfo.data.receiverName}}</span>
                  <span class="word32 word33">收货地址：{{detailInfo.data.receiverAddress}}</span>
                </div>
              </div>
              <span class="txt17">手机号码：{{detailInfo.data.receiverMobile?detailInfo.data.receiverMobile:'--'}}</span>
              <!-- <span class="txt17">配送方式：{{detailInfo.data.expressName}}</span> -->
            </div>
            <div class="outer10 flex-col"></div>
            <div class="box5">
              <!-- 	detailInfo.data.orderState
                0-已取消；10-提交订单待审核；20-审核通过待发货；21-审核拒绝订单结束；
                30-待收货；40-已完成；51-转化失败
              -->
              <!-- 已取消 -->
              <span class="word31" v-if="detailInfo.data.orderState == '0'">已取消</span>
              <!-- 待审核 -->
              <span class="word31" v-else-if="detailInfo.data.orderState == 10">等待供应商审核</span>
              <!-- 待发货 -->
              <span class="word31" v-else-if="detailInfo.data.orderState == 20">等待供应商发货</span>
              <!-- 待收货 -->
              <span class="word31" v-else-if="detailInfo.data.orderState == 30">等待买家确认收货</span>
              <!-- 审核拒绝 -->
              <span class="word31" v-else-if="detailInfo.data.orderState == 21">供应商审核拒绝，订单结束</span>
              <!-- 交易完成 -->
              <span class="word31"
                v-else-if="detailInfo.data.orderState == 40 || detailInfo.data.orderState == 51">完成交易</span>
              <div class="reason" v-if="detailInfo.data.orderState == '0'">取消原因：{{detailInfo.data.cancelReason}}</div>
              <div class="reason" v-else-if="detailInfo.data.orderState == 21">拒绝理由：{{detailInfo.data.refuseReason}}
              </div>
              <button class="group27 flex-col" @click="cancle"
                v-if="detailInfo.data.orderState==10 || detailInfo.data.orderState == 20">
                <span class="txt15">取消订单</span>
              </button>
              <button class="group28 flex-col" @click="editAddress"
                v-if="detailInfo.data.orderState==10 || detailInfo.data.orderState==20">
                <span class="txt15">修改地址</span>
              </button>
              <button class="group28 flex-col" @click="confirmReceipt" v-if="detailInfo.data.orderState==30">
                <span class="txt15">确认收货</span>
              </button>
              <!-- <button class="group28 flex-col" @click="editAddress" v-if="detailInfo.data.orderState==30 ||  detailInfo.data.orderState==40">
                <span class="txt15">查看物流</span>
              </button> -->
            </div>
          </div>

          <!-- 物流信息 start -->
          <template v-if="logistics_info.data.expressName">
            <span class="txt21">物流信息</span>
            <div class="sld_deliver_box">
              <div class="logistics_info">
                <p>{{L['承运人']}}：{{logistics_info.data.expressName}}</p>
                <p>{{L['运单号']}}：{{logistics_info.data.expressNumber}}</p>
              </div>
              <div class="logistics_list_con"
                v-if="logistics_info.data.routeList && logistics_info.data.routeList.length > 0">
                <el-timeline>
                  <el-timeline-item v-for="(logistics, index) in logistics_info.data.routeList" :key="index"
                    :timestamp="logistics.acceptStation ? logistics.acceptStation : logistics.remark">{{logistics.acceptTime}}</el-timeline-item>
                </el-timeline>
              </div>
              <SldCommonEmpty v-else :tip="logistics_info.data.reason" totalWidth="934px" />
            </div>
          </template>
          <!-- 物流信息 end -->

          <!-- 产品信息 start -->
          <span class="txt21">产品信息</span>
          <div class="mod5_box">
            <div class="section3 flex-col">
              <div class="mod5 flex-row">
                <span class="word36">序号</span>
                <span class="word36 info9">产品信息</span>
                <span class="word36 word37">单价</span>
                <span class="word36 word38">数量</span>
                <span class="word36 word39">总价</span>
              </div>
            </div>
            <template v-for="(goodsItem, goodsIndex) in detailInfo.data.orderProductList" :key="goodsItem.goodsId">
              <div class="section4 flex-col">
                <div class="mod6 flex-row">
                  <span class="word41">{{ goodsIndex + 1 }}</span>
                  <div class="mod7 flex-col" @click="detail(goodsItem.productId)">
                    <img class="section5" :src="goodsItem.goodsImage" />
                  </div>
                  <div class="mod8 flex-col">
                    <span class="infoBox1 overflow_ellipsis_two" @click="detail(goodsItem.productId)"
                      :title="(goodsItem.goodsName&&goodsItem.goodsName.length>50)?goodsItem.goodsName:''">
                      {{goodsItem.goodsName}}
                    </span>
                    <span class="word42 overflow_ellipsis_two" @click="detail(goodsItem.productId)">
                      {{goodsItem.specValues}}
                    </span>
                    <span class="pro_code" v-if="goodsItem.orderCode">订货编码：{{goodsItem.orderCode}}</span>

                  </div>
                  <!-- <div class="mod9 flex-col">
                      <span class="word43" v-for="attr in goodsItem.attributeList" :key="attr">
                        {{attr.attributeName}}：{{attr.attributeValue}}
                      </span>
                      <span v-show="goodsItem.attributeList.length==0" style="text-indent:60px;">-</span>
                    </div> -->
                  <div class="mod10 flex_row_center_center">
                    <span class="info12">￥{{goodsItem.goodsPrice.toFixed(2)}}</span>
                  </div>
                  <span class="word45">{{goodsItem.buyNum}}</span>
                  <span class="word45 txt22">
                    <dd v-if="goodsItem.moneyAmount">￥{{goodsItem.moneyAmount.toFixed(2)}}</dd>
                    <dd v-else>需询价</dd>
                  </span>
                </div>
              </div>
            </template>
          </div>
          <!-- 产品信息 end -->
        </div>
      </div>
    </div>
    <!-- 取消理由弹窗 start -->
    <el-dialog :title="L['取消订单理由']" v-model="cancel_order" customClass="select_reason_width"
      :before-close="current_reason_id=''" lock-sroll="false">
      <div class="cancel_list_con">
        <div v-for="(reasonItem,index) in cancel_list.data" :key="index"
          :class="{reason_item:true, flex_row_between_center:true,active:current_reason_id==reasonItem.reasonId}"
          @click="selectReason(reasonItem.reasonId)">
          <img :src="current_reason_id==reasonItem.reasonId?selected:not_select" alt />
          <span class="reason_text">{{reasonItem.content}}</span>
        </div>
      </div>
      <div class="confirm_cancel_btn" @click="confirmCancel()">{{L['确定']}}</div>
    </el-dialog>
    <!--  取消地址理由弹窗 end  -->

    <!-- 地址选择弹窗 start -->
    <el-dialog title="更换地址" v-model="show_select_address" customClass="select_address_width" lock-sroll="false">
      <div class="out_stock_dialog address_con">
        <div v-for="(item,index) in address_list.data" :key="index" @click="selectAddress(index)"
          :class="{address_item:true, flex_column_start_start:true, select:current_address_index==index}">
          <span>{{L['收货人']}}：{{item.memberName}}</span>
          <span>{{L['联系方式']}}：{{item.telMobile}}</span>
          <div class="address_text" :title="item.addressAll+item.detailAddress">
            <span>{{L['收货地址']}}：</span>
            <span class="overflow_ellipsis_clamp2">{{item.addressAll}} {{item.detailAddress}}</span>
          </div>
          <img v-if="current_address_index==index" class="selected" :src="invoice_selected" alt />
        </div>
      </div>
      <div class="btn_con flex_row_center_center">
        <div class="remove red" @click="confirmChangeAddress()">{{L['提交修改']}}</div>
      </div>
    </el-dialog>
    <!-- 地址选择弹窗 end -->
  </div>
</template>

<script>
  import { ref, reactive, getCurrentInstance, onMounted } from "vue";
  import MemberTitle from "../../../components/MemberTitle";
  import { useRoute, useRouter } from "vue-router";
  import { ElMessage, ElMessageBox } from 'element-plus';
  import SldCommonEmpty from '@/components/SldCommonEmpty';
  import { useStore } from 'vuex'
  export default {
    name: "order-requestInfo",
    components: {
      MemberTitle,
      SldCommonEmpty,
    },
    setup() {
      const router = useRouter();
      const route = useRoute();
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const orderSn = ref(route.query.orderSn);
      const detailInfo = reactive({ data: {} });
      const pic_confirm = require('@/assets/order/order_confirm.png');
      const pic_uncheck = require('@/assets/order/order_uncheck.png');
      const pic_check = require('@/assets/order/order_check.png');
      const pic_unsend = require('@/assets/order/order_unsend.png');
      const pic_send = require('@/assets/order/order_send.png');
      const pic_unfinish = require('@/assets/order/order_unfinish.png');
      const pic_finish = require('@/assets/order/order_finish.png');
      const storeChat = require('@/assets/cart/storeChat.png');
      const store = useStore()
      const logistics_info = reactive({
        data: {
          expressName: '',
          expressNumber: '',
          routeList: [],
          reason: ' '
        }
      });

      /** 取消原因 */
      const cancel_order = ref(false); //是否显示取消订单弹窗
      const current_reason_id = ref(); //记录当前已选择理由id
      const cancel_list = reactive({ data: [] });
      const not_select = require("@/assets/order/not_select.png");
      const selected = require("@/assets/order/selected.png");
      /** 取消原因 */

      /** 地址选择 */
      const show_select_address = ref(false);
      const address_list = reactive({ data: [] });
      const current_address_index = ref(0);
      const invoice_selected = require("@/assets/buy/invoice_selected.png");
      const orderAddress = reactive({ data: "" });
      /** 地址选择 */

      const getDetail = () => {
        let param = {
          orderSn: orderSn.value
        }
        proxy
          .$get(`v3/business/front/sampleOrderInfo/detail`, param)
          .then((res) => {
            if (res.state == 200) {
              detailInfo.data = res.data;
              if (detailInfo.data.orderState >= 30) {
                lookLogistics();
              }
            } else {
              let msg = res.msg ? res.msg : '订单详情数据错误'
              ElMessage.error(msg);
              setTimeout(() => {
                router.back();
              }, 1000)
            }
          })
      };

      const detail = (id) => { //查看商品详情
        router.push({
          path: `/goods/detail`,
          query: {
            productId: id
          }
        })
      };

      // 获取订单取消理由列表
      const getCancelList = () => {
        proxy
          .$get("v3/system/front/reason/list", {
            type: 104
          })
          .then(res => {
            if (res.state == 200) {
              cancel_list.data = res.data;
            } else {
              ElMessage(res.msg);
            }
          })
      };
      const cancle = () => {
        cancel_order.value = true;
      };
      //选择理由
      const selectReason = id => {
        current_reason_id.value = id;
      };
      //确认取消订单
      const confirmCancel = () => {
        if (!current_reason_id.value) {
          ElMessage.warning(L["请选择取消理由"]);
          return;
        }
        proxy
          .$post("v3/business/front/sampleOrderOperate/cancel", {
            orderSn: orderSn.value,
            reasonId: current_reason_id.value
          })
          .then(res => {
            if (res.state == 200) {
              ElMessage.success(L["取消订单成功"]);
              cancel_order.value = false;
              getDetail();
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      //确认收货
      const confirmReceipt = () => {
        ElMessageBox.confirm(L["确认收货?"], L["提示"], {
          confirmButtonText: L["确定"],
          cancelButtonText: L["取消"],
          type: "warning",
        })
          .then(() => {
            let param = {
              orderSn: orderSn.value
            }
            proxy
              .$post("v3/business/front/sampleOrderOperate/receive", param)
              .then((res) => {
                if (res.state == 200) {
                  ElMessage.success(L["确认收货成功"]);
                  getDetail();
                } else {
                  ElMessage(res.msg);
                }
              })
              .catch(() => {
                //异常处理
              });
          })
          .catch(() => { });
      };
      const editAddress = () => {
        getAddressList();
        show_select_address.value = true;
      };
      //获取地址列表
      const getAddressList = () => {
        proxy
          .$get("v3/member/front/memberAddress/list")
          .then(res => {
            if (res.state == 200) {
              address_list.data = res.data.list;
            } else {
              ElMessage(res.msg);
            }
          })
          .catch(() => {
            //异常处理
          });
      };
      //切换地址
      const selectAddress = index => {
        current_address_index.value = index;
      };
      //确认修改地址
      const confirmChangeAddress = () => {
        orderAddress.data = address_list.data[current_address_index.value];
        show_select_address.value = false;
        proxy
          .$post("v3/business/front/sampleOrderOperate/updateAddress", {
            orderSn: orderSn.value,
            addressId: orderAddress.data.addressId
          })
          .then(res => {
            if (res.state == 200) {
              ElMessage.success(res.msg);
              getDetail();
            } else {
              ElMessage.error(res.msg);
            }
          })
      };

      const chatInfo = (id) => { //客服
        getStoreDetail(id, (item) => {
          let chatInfo = {
            storeId: item.storeId,
            vendorAvatar: item.storeLogo,
            storeName: item.storeName,
            source: `样品订单详情进入 供应商:${item.storeName}`
          }
          store.commit('saveChatBaseInfo', chatInfo)

          let newWin = router.resolve({
            path: '/service',
            query: {
              vid: id
            }
          })
          window.open(newWin.href, '_blank');
        })
      };



      const getStoreDetail = (storeId, callback) => {
        proxy.$get('v3/seller/front/store/detail', { storeId }).then(res => {
          if (res.state == 200) {
            if (callback && typeof callback == "function") {
              callback(res.data)
            }
          }
        })
      }

      const lookLogistics = () => { //查看物流
        let param = {
          orderSn: orderSn.value
        }
        // 获取已发货数据
        proxy
          .$get("v3/business/front/logistics/sampleOrder/getTrace", param)
          .then(res => {
            if (res.state == 200) {
              if (!res.data.reason) {
                res.data.reason = '暂无详细物流信息';
              }
              logistics_info.data = res.data;
            } else {
              ElMessage(res.msg);
            }
          })
      };

      onMounted(() => {
        getDetail();
        getCancelList();
      });

      return {
        L,
        pic_confirm,
        pic_uncheck,
        pic_check,
        pic_unsend,
        pic_send,
        pic_unfinish,
        pic_finish,
        storeChat,
        orderSn,
        detailInfo,
        cancel_order,
        current_reason_id,
        cancel_list,
        not_select,
        selected,
        show_select_address,
        address_list,
        current_address_index,
        invoice_selected,
        orderAddress,
        detail,
        getCancelList,
        cancle,
        selectReason,
        confirmCancel,
        editAddress,
        getAddressList,
        selectAddress,
        confirmChangeAddress,
        confirmReceipt,
        chatInfo,
        logistics_info,
        lookLogistics,
      };
    },
  };
</script>
<style lang="scss" scoped>
  @import "../../../style/orderRequestInfo.scss";
</style>
<style lang="scss">
  .sld_sample_info {
    .select_reason_width {
      width: 560px !important;

      ::-webkit-scrollbar {
        width: 0 !important;
      }

      ::-webkit-scrollbar {
        width: 0 !important;
        height: 0;
      }
    }

    .cancel_list_con,
    .logistics_list_con {
      width: 520px;
      margin: 0 auto;
      height: 300px;
      overflow-y: scroll;
      padding-top: 1px;

      .reason_item {
        width: 100%;
        height: 40px;
        margin-bottom: 15px;
        padding: 0 25px;
        justify-content: flex-start;
        cursor: default;

        img {
          width: 16px;
          height: 16px;
          margin-right: 14px;
        }

        &.active {
          background: #f8f8f8;
        }
      }
    }

    .confirm_cancel_btn {
      width: 80px;
      height: 30px;
      background: $colorMain;
      border-radius: 3px;
      line-height: 30px;
      text-align: center;
      color: white;
      margin: 0 auto;
      margin-top: 20px;
      cursor: pointer;
    }

    .select_address_width {
      width: 500px !important;
    }

    //商品无货缺货
    .out_stock_dialog {
      width: 460px;
      margin: 0 auto;
      height: 330px;
      overflow-y: scroll;

      .good_item {
        font-size: 14px;

        img {
          width: 80px;
          height: 80px;
        }

        .good_info {
          margin-left: 10px;
        }

        .good_name {
          width: 320px;
          color: #333333;
          line-height: 14px;
          margin-top: 10px;
          display: inline-block;
        }

        .spec_num {
          margin-top: 26px;

          .good_spec {
            color: #999999;
          }

          .good_num {
            float: right;
            color: #333333;
          }
        }
      }
    }

    .btn_con {
      font-size: 14px;
      margin-top: 20px;
      cursor: pointer;

      .return {
        cursor: pointer;
        width: 60px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #333333;
        border-radius: 3px;
        border: 1px solid #dddddd;
      }

      .red {
        background-color: $colorMain;
        color: white;
      }

      .remove {
        width: 120px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 3px;
        background-color: $colorMain;
        margin-left: 10px;
        color: white;
      }
    }

    // 选择地址
    .address_con {
      height: 330px;
      overflow-y: scroll;
      scrollbar-width: none !important;
      -ms-overflow-style: none !important;

      .address_item {
        &:not(:first-child) {
          margin-top: 20px;
        }

        width: 458px;
        //    height: 104px;
        box-sizing: border-box;
        border: 1px solid #dfdfdf;
        position: relative;
        padding: 20px;

        span,
        div:not(:first-child) {
          margin-top: 12px;
        }

        .address_text {
          display: flex;
          width: 400px;
        }

        .selected {
          width: 14px;
          height: 14px;
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }

      .select {
        border: 1px solid $colorMain;
      }
    }

    .overflow_ellipsis_clamp2 {
      width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      word-break: break-all;
      line-height: 19px;
    }

    .select_address_width {
      ::-webkit-scrollbar {
        width: 0 !important;
      }

      ::-webkit-scrollbar {
        width: 0 !important;
        height: 0;
      }
    }
  }
</style>